// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Services from 'components/units/unitPages/unit/services';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import MainServices from 'components/promptService/mainServices';
import { HeaderContext } from 'context/header';

// Image

// import MapImage from 'images/mapsImages/cdi-bsb.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};
  margin-top: 4.313rem;

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const GridMainServices = styled.div`
  margin-bottom: 4.375rem;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 1.25rem 0;
    border-radius: 8px;
  }

  p {
    line-height: 24px !important;
    margin-bottom: 40px !important;
  }

  ul {
    li {
      margin-bottom: 25px;
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottomPart && '1px solid #C6C8CC'};
  ul {
    margin-bottom: 0;
    li {
      :last-child {
        margin-bottom: 2px;
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
    margin-bottom: ${props => props.lastChild && '0'};
    @media only screen and (min-width: 1100px) {
      margin-bottom: ${props => props.lastChild && '3.75rem'};
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: ${props => (props.isFitCardButtonPages ? '1.875rem' : '0')};
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }
`;

const PartDropdown = styled.div`
  grid-column: ${props => props.gridColumn};
  border-top: 1px solid #c6c8cc;
  padding-top: 20px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    padding-top: 4.375rem;
    margin-bottom: 4.75rem;
  }
  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 10;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: initial;
    }
    :first-child {
      h2 {
        margin-top: 0;
      }
    }
    .dropdown {
      width: 100%;
      flex-direction: column;
      @media only screen and (min-width: 768px) {
        width: 49%;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];

    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, markdownCards) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('9.063rem');
      setMainMarginTopMobile('7.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);
  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw3w4gk859tt0d69cdom3x04', true)}
            fragments={getMarkdown(page, 'ckw3w4gk859tt0d69cdom3x04')}
            padding="0 0 4.375rem"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            contentTagImgTransform="unset"
            withoutBorderTop
            contentImgWidthLaptop="100%"
            notDuplicateUl={'none'}
            noPaddingMobile
            paddingContainer="20px 0 10px"
            listBullets
          />
        </Part>
        <PartDropdown gridColumn={'2 / -2'}>
          {/* Titulo*/}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckvws6d00kyti0b66b3xdjgrp', true)}
            fragments={getMarkdown(page, 'ckvws6d00kyti0b66b3xdjgrp')}
            padding="0 0 0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            contentTagImgTransform="unset"
            withoutBorderTop
            contentImgWidthLaptop="unset"
            noPaddingTop
            contactFlex="flex"
            contentContactCardChild5
            paddingBottom
            tittleMarginBottom
          />

          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw23ecawewc90b7255zzhzjm', true)}
            fragments={getMarkdown(page, 'ckw23ecawewc90b7255zzhzjm')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            contentFontSizeMob="0.875rem"
            isFragmentTitle
            displayTitle="none"
            cuidados
            paddingLeftListDesk=".8rem"
            paddingRightListDesk="2rem"
            infusion
            isPageDoeSangue
            dropdownMarginBottom
          />
          {/* Dropdown2 */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw23gpy8eyls0a264pkg6cb0', true)}
            fragments={getMarkdown(page, 'ckw23gpy8eyls0a264pkg6cb0')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            contentFontSizeMob="0.875rem"
            isFragmentTitle
            displayTitle="none"
            cuidados
            paddingLeftListDesk=".8rem"
            paddingRightListDesk="2rem"
            infusion
            isPageDoeSangue
            dropdownMarginBottom
          />
          {/* Dropdown3 */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw23iclcex220b72jqon0bgn', true)}
            fragments={getMarkdown(page, 'ckw23iclcex220b72jqon0bgn')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            contentFontSizeMob="0.875rem"
            isFragmentTitle
            displayTitle="none"
            cuidados
            paddingLeftListDesk=".8rem"
            paddingRightListDesk="2rem"
            infusion
            isPageDoeSangue
            dropdownMarginBottom
          />
          {/* Dropdown4 */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw23k8hseudi0c29lpa72lqs', true)}
            fragments={getMarkdown(page, 'ckw23k8hseudi0c29lpa72lqs')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            contentFontSizeMob="0.875rem"
            isFragmentTitle
            displayTitle="none"
            cuidados
            paddingLeftListDesk=".8rem"
            paddingRightListDesk="2rem"
            infusion
            isPageDoeSangue
            dropdownMarginBottom
          />
          {/* Dropdown5 */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw23makgevux0b6672pr3p2k', true)}
            fragments={getMarkdown(page, 'ckw23makgevux0b6672pr3p2k')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            contentFontSizeMob="0.875rem"
            isFragmentTitle
            displayTitle="none"
            cuidados
            paddingLeftListDesk=".8rem"
            paddingRightListDesk="2rem"
            infusion
            isPageDoeSangue
            dropdownMarginBottom
          />
          {/* Dropdown6 */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw23obvceuy70c293r1u6j5d', true)}
            fragments={getMarkdown(page, 'ckw23obvceuy70c293r1u6j5d')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            contentFontSizeMob="0.875rem"
            isFragmentTitle
            displayTitle="none"
            cuidados
            paddingLeftListDesk=".8rem"
            paddingRightListDesk="2rem"
            infusion
            isPageDoeSangue
            dropdownMarginBottom
          />
        </PartDropdown>
        <GridMainServices>
          <Part gridColumn={'2 / -2'}>
            <MainServices
              markdown={getMarkdown(page, 'ckw3yd4406u710c78wquiq6m0', true)}
              infusion
              paddingCentroCirurgicoSP
            ></MainServices>
          </Part>
        </GridMainServices>
        <Part gridColumn={'2 / -2'} notLeft borderBottomPart>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckw3yn1d476ee0a70lrois9vg', true)}
            fragments={getMarkdown(page, 'ckw3yn1d476ee0a70lrois9vg')}
            padding="0 0 4.375rem"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            contentTagImgTransform="translate(0, 97%)"
            withoutBorderTop
            contentImgWidthLaptop="unset"
            noPaddingTop
            contactFlex="block"
            contentContactCardChild5
            paddingBottom
            numberInformation
            contentContactCardChild6
            contactChild6CentroCirurgicoSP
          />
        </Part>
        <Part gridColumn={'2 / -2'} lastChild>
          <Services
            markdown={markdownCards}
            fragments={getMarkdown(page, 'ckw3youy879qq0d696l56rsf3')}
            cardHowCanIHelp
            isportalPaciente="17.125rem"
            titleDeskWithPortalPaciente="100%"
            titleDeskMaxWithPortalPaciente="210px"
            heightTitleCardPortalPaciente="58px"
            marginBottomCardPortalPaciente="20px"
            widthTextHiperlink="210px"
            marginLeftPortalPaciente="0 !important"
            isCDIUnits
            isActiveCard={true}
            noMarginCardStyle
            isAdjustmentInCssLayoutMobile
            isPortalPaciente
            paddingBottom="20px"
            prevNextButton
            isCentroCirurgico
            marginRightCard="1.875rem"
          />
        </Part>
      </GridContainer>
    </>
  );
};

const CCIBrasilia = ({ page, location, specialties }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [markdownCards, setMarkdownCards] = useState('');
  let arrCards = '';

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    arrCards = specialties
      .filter(item => item.centroCirurgico === true && item.unity == 'sp')
      .map(item => item.markdownCards)
      .join('(end)');
    setMarkdownCards(arrCards);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckw3vzsco57qz0a70mekrn07u', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, markdownCards)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckw3vq0i053fn0d695rbf9l4o" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
            specialties {
              name
              unity
              centroCirurgico
              markdownCards
            }
          }
        }
      `}
      render={response => {
        return (
          <CCIBrasilia
            page={response.gcms.site.pages}
            specialties={response.gcms.specialties}
            location={location}
          />
        );
      }}
    />
  );
};
